@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.App {
  background: rgb(2, 0, 36);
  background: linear-gradient(335deg, rgba(2, 0, 36, 1) 0%, rgba(217, 242, 180, 1) 100%, rgba(0, 212, 255, 1) 100%);
  color: white;
  font-family: 'Roboto', sans-serif;
  height: 100vh;
  text-align: center;
}

.title-header {
  font-size: 5vh;
  font-weight: 100;
  letter-spacing: 1vw;
  margin-top: 0;
}

.footer {
  font-size: 2vh;
  font-weight: 100;
  margin-top: 4vh;
}